exports.components = {
  "component---packages-blog-gatsby-blog-core-src-templates-collection-author-js": () => import("./../../../../packages/blog/gatsby-blog-core/src/templates/collection.author.js" /* webpackChunkName: "component---packages-blog-gatsby-blog-core-src-templates-collection-author-js" */),
  "component---packages-blog-gatsby-blog-core-src-templates-collection-category-js": () => import("./../../../../packages/blog/gatsby-blog-core/src/templates/collection.category.js" /* webpackChunkName: "component---packages-blog-gatsby-blog-core-src-templates-collection-category-js" */),
  "component---packages-blog-gatsby-blog-core-src-templates-collection-tag-js": () => import("./../../../../packages/blog/gatsby-blog-core/src/templates/collection.tag.js" /* webpackChunkName: "component---packages-blog-gatsby-blog-core-src-templates-collection-tag-js" */),
  "component---packages-blog-gatsby-blog-core-src-templates-post-js": () => import("./../../../../packages/blog/gatsby-blog-core/src/templates/post.js" /* webpackChunkName: "component---packages-blog-gatsby-blog-core-src-templates-post-js" */),
  "component---packages-blog-gatsby-blog-core-src-templates-posts-js": () => import("./../../../../packages/blog/gatsby-blog-core/src/templates/posts.js" /* webpackChunkName: "component---packages-blog-gatsby-blog-core-src-templates-posts-js" */),
  "component---packages-blog-gatsby-blog-pages-src-pages-404-jsx": () => import("./../../../../packages/blog/gatsby-blog-pages/src/pages/404.jsx" /* webpackChunkName: "component---packages-blog-gatsby-blog-pages-src-pages-404-jsx" */),
  "component---packages-blog-gatsby-blog-pages-src-pages-cerez-politikasi-jsx": () => import("./../../../../packages/blog/gatsby-blog-pages/src/pages/cerez-politikasi.jsx" /* webpackChunkName: "component---packages-blog-gatsby-blog-pages-src-pages-cerez-politikasi-jsx" */),
  "component---packages-blog-gatsby-blog-pages-src-pages-gizlilik-politikasi-jsx": () => import("./../../../../packages/blog/gatsby-blog-pages/src/pages/gizlilik-politikasi.jsx" /* webpackChunkName: "component---packages-blog-gatsby-blog-pages-src-pages-gizlilik-politikasi-jsx" */),
  "component---packages-blog-gatsby-blog-pages-src-pages-hakkimizda-jsx": () => import("./../../../../packages/blog/gatsby-blog-pages/src/pages/hakkimizda.jsx" /* webpackChunkName: "component---packages-blog-gatsby-blog-pages-src-pages-hakkimizda-jsx" */),
  "component---packages-blog-gatsby-blog-pages-src-pages-iletisim-jsx": () => import("./../../../../packages/blog/gatsby-blog-pages/src/pages/iletisim.jsx" /* webpackChunkName: "component---packages-blog-gatsby-blog-pages-src-pages-iletisim-jsx" */),
  "component---packages-blog-gatsby-blog-pages-src-pages-yazarlar-jsx": () => import("./../../../../packages/blog/gatsby-blog-pages/src/pages/yazarlar.jsx" /* webpackChunkName: "component---packages-blog-gatsby-blog-pages-src-pages-yazarlar-jsx" */)
}

